import React, { useEffect, useContext, useState, useRef } from 'react';
import { DataContext } from "contexts/DataContext";
import { useNavigate, Navigate } from 'react-router-dom';
import { isMobile, isSafari } from 'react-device-detect';
import { Modal } from "antd"
import moment from "moment";
import axios from "axios";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';

import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import brand from '../contexts/brand';

import ReportModal from "components/ReportModal";
import ViewerModal from "components/ViewerModal";

import { classes } from './styles/DashboardStyle';

import logo from 'images/human_logo.png';
import humanDr from 'images/human_dr.png';
import vetDr from 'images/vet_dr.png';
import humanLogoHeader from 'images/human_logo_header.png';
import vetLogoHeader from 'images/vet_logo_header.png';
import backgroundHuman from 'images/background_human.png';
import backgroundVet from 'images/background_vet.png';


const CHECK_DOWNLOAD_INTERVAL = 3000;
const CHECK_PRINT_INTERVAL = 3000;
const MAX_PRINT_INTERVAL = 15*60*1000; // 15 minutos máximo de espera


const Dashboard = () => {
  const isSmall = useMediaQuery('(max-width: 800px)');

  const navigate = useNavigate();

  const { data } = useContext(DataContext);
  const [laudoOpen, setLaudoOpen] = useState(false);
  const [viewerOpen, setViewerOpen] = useState(false);

  const [downloadStatus, setDownloadStatus] = useState("none");
  const downloadZipUrl = useRef();

  const [printStatus, setPrintStatus] = useState("none");
  const printTotalTime = useRef();
  const printCanceled = useRef(false);
  const [printBuffer, setPrintBuffer] = useState();

  const hasData = Object.values(data).length;

  const [account, setAccount] = useState();
  const accountId = data?.study?.accountId;

  useEffect(() => {
    if(accountId){
      axios
        .get(`/external/account/${accountId}`)
        .then((result) => {
          setAccount(result.data)
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [accountId]);

  async function handleDownloadStudy() {
    try {
      setDownloadStatus("zipping");
      const { shortKey, downloadUrl } = await axios
        .get(data.downloadStudyLink)
        .then((res) => res.data);
      downloadZipUrl.current = downloadUrl;

      let id = setInterval(checkDownload, CHECK_DOWNLOAD_INTERVAL);

      async function checkDownload() {
        const result = await axios
          .get(`${data.checkDownloadLink}&shortKey=${encodeURIComponent(shortKey)}`)
          .then((res) => res.data)
          .catch(e => {
            clearInterval(id);
            setDownloadStatus(e.toString());
          });
        if (result === true) {
          clearInterval(id);
          setDownloadStatus("done");
        }
      }
    } catch(e) {
      console.log('Error downloading zip: ', e.toString());
      setDownloadStatus(e.toString());
    }
  }

  async function handleDownloadIamges() {
    try {
      setDownloadStatus("zipping");
      const { shortKey, downloadUrl } = await axios
        .get(data.downloadImagesLink)
        .then((res) => res.data);
      downloadZipUrl.current = downloadUrl;

      let id = setInterval(checkDownload, CHECK_DOWNLOAD_INTERVAL);

      async function checkDownload() {
        const result = await axios
          .get(`${data.checkDownloadLink}&shortKey=${encodeURIComponent(shortKey)}`)
          .then((res) => res.data)
          .catch(e => {
            clearInterval(id);
            setDownloadStatus(e.toString());
          });
        if (result === true) {
          clearInterval(id);
          setDownloadStatus("done");
        }
      }
    } catch(e) {
      console.log('Error downloading zip: ', e.toString());
      setDownloadStatus(e.toString());
    }
  }

  const doDownloadStudy = () => {
    let link = document.createElement("a");
    link.href = downloadZipUrl.current;
    link.dispatchEvent(new MouseEvent("click"));
  };

  const [urlLaudo, setUrlLaudo] = useState();
  const [loadingLaudo, setLoadingLaudo] = useState(false);
  const handleOpen = async () => {
    setLoadingLaudo(true);

    const pdfUrl = data.report.downloadUrl;
    const pdfBody = await axios
      .get(pdfUrl, { responseType: 'blob' })
      .then(res => res.data);
    const file = new Blob([pdfBody], { type: 'application/pdf' });

    axios.post('/external/insertResultsPortalLogs', {
      type: 'openReport',
      studyId: data?.study?.id,
      patientName: data?.study?.patientName,
      accountId
    }).then(() => {}).catch((err) => { console.error(err); });
    
    if(isMobile) {
      if(isSafari) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const element = document.createElement('a');
          element.href = window.URL.createObjectURL(file);
          element.download = "laudo.pdf";
          element.click();

          setLaudoOpen(false);
          setLoadingLaudo(false);
        };
      } else {
        window.open(window.URL.createObjectURL(file), "_blank");
        setLaudoOpen(false);
        setLoadingLaudo(false);
      }
    } else {
      setUrlLaudo(URL.createObjectURL(file));
      setLaudoOpen(true);
      setLoadingLaudo(false);
    }
  };

  useEffect(() => {
    if (printCanceled.current === false && printBuffer) {
      // Abrindo o PDF
      try {
        const file = new Blob([printBuffer], { type: "application/pdf" });
        const printWindow = window.open(
          window.URL.createObjectURL(file),
          "_blank"
        );
        if (!printWindow) {
          throw new Error("Verifique o bloqueador de pop-ups do seu navegador");
        }
      } catch (e) {
        window.alert(e.message);
      }
    };
    setPrintBuffer(null);
    setPrintStatus('none');
  }, [printBuffer]);

  async function handlePrintStudy() {
    try {
      setPrintStatus("printing");
      printCanceled.current = false;
      const { downloadUrl } = await axios
        .get(data.printStudyLink)
        .then((res) => res.data)
        .catch(e => setPrintStatus(e.toString()));
      const printUrl = decodeURI(downloadUrl);
      printTotalTime.current = 0;

      setTimeout(checkPrint, CHECK_PRINT_INTERVAL);

      // Vou ficar esperando até o PDF ser criado no S3
      async function checkPrint() {
        const result = await axios
          .get(printUrl, { responseType: 'arraybuffer' })
          .then(res => new Blob([new Uint8Array(res.data)]))
          .catch(e => {
            printTotalTime.current += CHECK_PRINT_INTERVAL;
            // Se deu 404 continuo esperando, a menos que já ultrapassou o tempo máximo de espera
            if (e?.response?.status !== 404 || printTotalTime.current > MAX_PRINT_INTERVAL) {
              setPrintStatus(e.toString());
              printCanceled.current = true;
            } else {
              if (printCanceled.current === false) {
                setTimeout(checkPrint, CHECK_PRINT_INTERVAL);
              }
            }
          });
        if (!!result && printCanceled.current === false) {
          setPrintBuffer(result);

          axios.post('/external/insertResultsPortalLogs', {
            type: 'printStudy',
            studyId: data?.study?.id,
            patientName: data?.study?.patientName,
            accountId
          }).then(() => {}).catch((err) => { console.error(err); });
        }
      }
    } catch(e) {
      console.log('Error printing zip: ', e.toString());
      setPrintStatus(e.toString());
    }
  }

  const whatLogo = data?.study?.institutionLogo || data?.study?.accountLogo || false;

  if (!hasData) return <Navigate to="/" />

  return (
    <>
      <AppBar color="primary" position="static" sx={classes.appBar}>
        <img src={brand === 'vet' ? vetLogoHeader : humanLogoHeader} alt={brand === 'vet' ? 'Dr. Nuvem' : 'Dr. Tis'} style={{maxHeight: 30}} />

        <Button
          endIcon={<ExitToAppIcon />}
          onClick={() => navigate("/")}
          variant="contained"
          color="primary"
          sx={{ml: "auto"}}
        >
          Sair
        </Button>
      </AppBar>

      <div
        style={{
          height: '100vh',
          backgroundImage: !isSmall && (brand === 'vet' ? `url(${backgroundVet}`: `url(${backgroundHuman})`),
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}
      >
        <Container sx={classes.container}>
          <Modal
            maskClosable={false}
            title="Baixar estudo"
            visible={downloadStatus !== "none"}
            okButtonProps={{
              disabled: downloadStatus !== "done",
              loading: downloadStatus === "zipping",
            }}
            onOk={() => doDownloadStudy()}
            okText="Baixar"
            onCancel={() => {
              setDownloadStatus("none");
            }}
          >
            {downloadStatus === "zipping" && "Gerando o zip das imagens"}
            {downloadStatus === "done" &&
              'Pronto! Clique em "Baixar" para fazer o download'}
            {downloadStatus.startsWith('Erro') && downloadStatus}
          </Modal>

          <Modal
            maskClosable={false}
            title="Imprimir estudo"
            visible={printStatus !== "none"}
            okButtonProps={{
              disabled: printStatus !== "done",
              loading: printStatus === "printing",
            }}
            onCancel={() => {
              setPrintStatus("none");
              printCanceled.current = true;
            }}
          >
            {printStatus === "printing" && "Imprimindo o estudo"}
            {printStatus.startsWith('Erro') && printStatus}
          </Modal>

          <Paper sx={classes.paper}>
            <Grid sx={classes.infoInstitution}>
              {whatLogo ? (
                <Box component='img' src={whatLogo} alt={data?.study?.institutionName ? data.study.institutionName : "Dr. TIS"} sx={classes.logo} />
              ) : (
                <Box component='img' src={brand === 'vet' ? vetDr : humanDr} alt={brand === 'vet' ? 'Dr. Nuvem' : 'Dr. Tis'} sx={classes.logo} />
              )}
              {data?.study?.institutionName && (
                <Grid item>
                  <Typography variant="h4" display="block">Instituição</Typography>
                  <Typography variant="h3" as="span" display="block">
                    {data.study.institutionName}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container sx={classes.informations}>
              {data?.study?.patientName && (
                <Grid item>
                  <Typography variant="h4">Nome do paciente</Typography>
                  <Typography variant="h3">
                    {data.study.patientName}
                  </Typography>
                </Grid>
              )}
              {data?.study?.patientResponsiblePerson && (
                <Grid item>
                  <Typography variant="h4">Responsável</Typography>
                  <Typography variant="h3">
                    {data?.study?.patientResponsiblePerson}
                  </Typography>
                </Grid>
              )}
              {data?.study?.examType && (
                <Grid item>
                  <Typography variant="h4">Tipo do exame</Typography>
                  <Typography variant="h3">
                    {data?.study?.examType}
                  </Typography>
                </Grid>
              )}
              <Grid container xs={12} style={{display: 'flex', gap: '1.2rem'}}>
                {data?.study?.referringPhysicianName && (
                  <Grid item xs={12} sm={5}>
                    <Typography variant="h4">Médico requisitante</Typography>
                    <Typography variant="h3">
                      {data?.study?.referringPhysicianName}
                    </Typography>
                  </Grid>
                )}
                {data?.study?.dicomCreatedAt && (
                  <Grid item xs={12} sm={5}>
                    <Typography variant="h4">Data do exame</Typography>
                    <Typography variant="h3">
                      {moment(data?.study?.dicomCreatedAt).format("DD/MM/YYYY")}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>

          {account?.notificationResultsPortal !== false && !data?.report?.downloadUrl && (
            <Alert severity="warning" sx={{mb: 3}}>
              <AlertTitle>Atenção!</AlertTitle>
              O laudo deste estudo ainda não está disponível. Entre em contato com a clínica ou hospital para saber mais sobre os prazos de liberação.
            </Alert>
          )}

          {(data.study.storageClass === 'GLACIER' || data.study.storageClass === 'DEEP_ARCHIVE') && (
            <Alert severity="warning" sx={{mb: 3}}>
              <AlertTitle>Atenção!</AlertTitle>
              O estudo está arquivado. Solicite a recuperação ao administrador da clinica em que o exame foi realizado para que consiga visualizar as imagens.
            </Alert>
          )}

          <Grid style={{display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(auto-fit, minmax(210px, 1fr))'}}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleOpen}
              disabled={!data?.report?.downloadUrl}
              sx={classes.button}
            >
              Abrir laudo
              {loadingLaudo && (
                <CircularProgress
                  size={20}
                  style={{ marginLeft: 25, color: 'white' }}
                />
              )}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => setViewerOpen(true)}
              sx={classes.button}
              disabled={data.study.storageClass === 'GLACIER' || data.study.storageClass === 'DEEP_ARCHIVE'}
            >
              Ver imagens
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handlePrintStudy}
              disabled={!data?.printImagesLink
                || (data.study.storageClass === 'GLACIER' || data.study.storageClass === 'DEEP_ARCHIVE')}
              sx={classes.button}
            >
              Imprimir imagens
            </Button>
            {data?.downloadImagesLink && (
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleDownloadIamges}
                disabled={!data?.downloadImagesLink
                  || (data.study.storageClass === 'GLACIER' || data.study.storageClass === 'DEEP_ARCHIVE')}
                sx={classes.button}
              >
                Baixar imagens
              </Button>
            )}
            {data?.downloadStudyLink && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleDownloadStudy}
                disabled={!data?.downloadStudyLink
                  || (data.study.storageClass === 'GLACIER' || data.study.storageClass === 'DEEP_ARCHIVE')}
                sx={classes.button}
              >
                Baixar imagens DICOM
              </Button>
            )}
          </Grid>

          {brand === 'human' && isSmall &&
            <Box sx={{textAlign: "center", marginTop: '7.2vw'}}>
              <a href="https://drtis.com.br">
                <img src={logo} alt={'Dr. Tis'} height="42" />
              </a>
            </Box>
          }
        </Container>
      </div>
      
      { !isMobile && <ReportModal open={laudoOpen} url={urlLaudo} onClose={() => setLaudoOpen(false)} /> }
      <ViewerModal open={viewerOpen} viewerUrl={data.viewerLink} onClose={() => setViewerOpen(false)} />
    </>
  );
};

export default Dashboard;